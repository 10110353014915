<script>
import {
  DraggableElement,
  DraggableArea,
  PreviewResult,
  ResizeEvent,
} from "vue-advanced-cropper";

export default {
  name: "CircleStencil",
  components: {
    PreviewResult,
    DraggableArea,
    DraggableElement,
  },
  props: {
    img: {
      type: Object,
    },
    resultCoordinates: {
      type: Object,
    },
    stencilCoordinates: {
      type: Object,
    },
  },
  computed: {
    style() {
      const { height, width, left, top } = this.stencilCoordinates;
      return {
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
      };
    },
  },
  methods: {
    onMove(moveEvent) {
      this.$emit("move", moveEvent);
    },
    onHandlerMove(dragEvent) {
      const shift = dragEvent.shift();

      const widthResize = shift.left;
      const heightResize = -shift.top;

      this.$emit(
        "resize",
        new ResizeEvent(dragEvent.nativeEvent, {
          left: widthResize,
          right: widthResize,
          top: heightResize,
          bottom: heightResize,
        })
      );
    },
    aspectRatios() {
      return {
        minimum: 1,
        maximum: 1,
      };
    },
  },
};
</script>

<template>
  <div class="circle-stencil" :style="style">
    <DraggableElement class="circle-stencil__handler" @drag="onHandlerMove">
      <img src="/public/handler.svg" alt="" />
    </DraggableElement>
    <DraggableArea @move="onMove">
      <PreviewResult
        class="circle-stencil__preview"
        :img="img"
        :stencilCoordinates="stencilCoordinates"
        :resultCoordinates="resultCoordinates"
      />
    </DraggableArea>
  </div>
</template>
